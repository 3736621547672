<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	export let text: string | undefined;
	export let loading: boolean | undefined = false;
	export let disabled: boolean | undefined = false;
	export let className: string | undefined = '';
	export let iconPosition: 'left' | 'right' | undefined = undefined;
	export let buttonRef: HTMLButtonElement | undefined = undefined;

	export { className as class };
</script>

<button
	on:click
	bind:this={buttonRef}
	{...$$restProps}
	class={classes(
		className,
		'inline-flex justify-center items-center px-10 py-2.5 min-w-35 font-semibold focus:outline focus:outline-4 focus:outline-accent-primary_01 rounded-full tracking-[.03125em] transition duration-500',
		disabled &&
			'disabled:text-dark-tertiary border disabled:border-dark-background disabled:bg-dark-background'
	)}
	disabled={disabled || loading}
>
	{#if iconPosition === 'left' && !loading && $$slots.icon}
		<slot name="icon" />
	{/if}
	{#if loading}
		<svg
			class="w-4 h-4 -ml-1 animate-spin"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
		>
			<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"
			></circle>
			<path
				class="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
			></path>
		</svg>
	{/if}
	{#if text}
		<span
			class={($$slots.icon || loading) && iconPosition === 'left'
				? 'ml-2'
				: $$slots.icon && iconPosition === 'right'
					? 'mr-2'
					: ''}>{text}</span
		>
	{/if}
	{#if iconPosition === 'right' && !loading && $$slots.icon}
		<slot name="icon" />
	{/if}
</button>
